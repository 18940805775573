import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './mobileNavbar.css';
import EnglishmateLogo from './LogoMobile.png';
import { debounce } from 'lodash';

function MobileNavbar() {
  const [scrollStage, setScrollStage] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (window.scrollY > 550) {
        setScrollStage(3);
      } else if (window.scrollY > 500) {
        setScrollStage(2);
      } else if (window.scrollY > 150) {
        setScrollStage(1);
      } else {
        setScrollStage(0);
      }
    }, 100);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  const closeMenu = () => {
    if (menuOpen) {
      setMenuOpen(false);
      document.body.style.overflow = 'auto';
    }
  };

  const navbarClasses = useMemo(() => {
    return `mobile-navbar stage-${scrollStage} ${menuOpen ? 'menu-open' : ''}`;
  }, [scrollStage, menuOpen]);

  return (
    <nav className={navbarClasses}>
      <div className="mobile-navbar-content">
        <Link to="" onClick={closeMenu}> {/* Añadido onClick para cerrar el menú */}
          <img
            src={EnglishmateLogo}
            alt="Logo de EnglishMate"
            className={`mobile-navbar-logo ${scrollStage >= 1 || menuOpen ? 'mobile-navbar-logo-transition' : ''}`}
          />
        </Link>
        {scrollStage >= 2 && (
          <div className="mobile-hamburger-menu" onClick={toggleMenu}>
            {menuOpen ? <span className="close-icon">&times;</span> : <span className="hamburger-icon">&#9776;</span>}
          </div>
        )}
      </div>
      {menuOpen && (
        <div className="mobile-menu">
          <div className="mobile-menu-scrollable">
            <div className="mobile-menu-section">
              <Link
                to="/adultos"
                className={`mobile-menu-item ${location.pathname === '/adultos' ? 'active' : ''}`}
                onClick={toggleMenu}
              >
                Jóvenes & Adultos
              </Link>
            </div>
            <div className="mobile-menu-section">
              <Link
                to="/school"
                className={`mobile-menu-item ${location.pathname === '/school' ? 'active' : ''}`}
                onClick={toggleMenu}
              >
                Niños & Adolescentes
              </Link>
            </div>
            <div className="mobile-menu-section">
              <Link
                to="/nosotros"
                className={`mobile-menu-item ${location.pathname === '/nosotros' ? 'active' : ''}`}
                onClick={toggleMenu}
              >
                Nosotros
              </Link>
            </div>
            <div className="mobile-menu-section">
              <Link
                to="/cursos"
                className={`mobile-menu-item ${location.pathname === '/cursos' ? 'active' : ''}`}
                onClick={toggleMenu}
              >
                El Curso
              </Link>
            </div>
          </div>
          <div className="mobile-menu-actions">
            <a href="https://wa.me/message/CYQZH2SKT227H1" className="mobile-navbar-button" onClick={toggleMenu}>
              Matricúlate Ya
            </a>
            <a href="https://wa.me/message/CYQZH2SKT227H1" className="mobile-navbar-button-secondary" onClick={toggleMenu}>
              Ingreso Alumnos
            </a>
          </div>
        </div>
      )}
    </nav>
  );
}

export default MobileNavbar;
