import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import Navbar from './components/navbar/Navbar';
import MobileNavbar from './components/mobileNavbar/MobileNavbar';
import Home from './pages/Home/Home';
import School from './pages/School/School';
import Cursos from './pages/Cursos/Cursos';
import Nosotros from './pages/Nosotros/Nosotros';
import Adultos from './pages/Adultos/Adultos';

import WhatsAppIcon from './assets/whatsapp-icon.svg';
import ScrollToTop from './components/ScrollToTop'; 
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

/**
 * Tracks page views on route changes using ReactGA (GA4).
 */
const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Log pageview to GA
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return null;
};

function App() {
  useEffect(() => {
    // 1) Initialize GA4
    ReactGA.initialize('G-TXZVT9ZMW'); // Replace with your GA4 Measurement ID

    // 2) Initialize GTM
    const tagManagerArgs = {
      gtmId: 'GTM-T9ZLGVMW', // Replace with your GTM Container ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Router>
      {/* Ensures the window scrolls to top on every route change */}
      <ScrollToTop />
      {/* Tracks route changes for GA */}
      <RouteTracker />

      <div className="App">
        <Navbar />
        <MobileNavbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/School" element={<School />} />
          <Route path="/Cursos" element={<Cursos />} />
          <Route path="/Nosotros" element={<Nosotros />} />
          <Route path="/Adultos" element={<Adultos />} />
        </Routes>

        {/* WhatsApp Button Container */}
        <div className="whatsapp-button-container">
          <a
            href="https://wa.me/message/PUGX3ZO4SXBOG1"
            className="whatsapp-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={WhatsAppIcon} alt="WhatsApp" className="whatsapp-icon" />
            <span>¡Háblanos por WhatsApp!</span>
          </a>
        </div>
      </div>
    </Router>
  );
}

export default App;
