import React from 'react';
import PlanCard from './PlanesCard'; 
import PlanesCardMobile from './PlanesCardMobile'; 
import './PlanesSection.css';

function PlanesSection() {
  return (
    <section className="planes-section">
      <h2 className="planes-section-title">Nuestros Planes</h2>

      {/* Desktop Plan Cards */}
      <div className="planes-section-cards desktop-only">
        <PlanCard
          title="Plan Mensual"
          badge="Mensual"
          description="¡Perfecto para empezar!"
          price="S/<span style='text-decoration: line-through;'>199</span>/99/mes"
          priceDetails="Cobro mensual recurrente"
          benefits={[
            "+8 horas semanales",
            "Clases de conversación en vivo",
            "Acceso a material educativo",
            "Certificados digitales",
          ]}
          nonBenefits={[
            "Talleres: Work & Travel, Business English, Medical English y más",
            "Disponibilidad para acceder a becas",
            "Acceso a la bolsa de trabajo EnglishJobs",
          ]}
          buttonText="Elige Pack Mensual"
          buttonLink="https://wa.me/message/PUGX3ZO4SXBOG1"
        />
        <PlanCard
          title="Pack Semestral"
          badge="Semestral"
          description="¡Aprende por Nivel!"
          price="S/<span style='text-decoration: line-through;'>699</span>/299/x 4 meses"
          priceDetails="Cobro cada 4 meses"
          benefits={[
            "+8 horas semanales",
            "Clases de conversación en vivo",
            "Acceso a material educativo",
            "Certificados digitales",
            "Talleres: Work & Travel, Business English, Medical English y más",
            "Disponibilidad para acceder a becas",
            "Acceso a la bolsa de trabajo EnglishJobs",
          ]}
          nonBenefits={[]}
          buttonText="Elige Pack Semestral"
          buttonLink="https://wa.me/message/PUGX3ZO4SXBOG1"
        />
        <PlanCard
          title="Pack Completo"
          badge="Anual"
          description="¡Todos los niveles!"
          price="S/<span style='text-decoration: line-through;'>1999</span>/699/x 12 meses"
          priceDetails="Cobro Único"
          benefits={[
            "+8 horas semanales",
            "Clases de conversación en vivo",
            "Acceso a material educativo",
            "Certificados digitales",
            "Talleres: Work & Travel, Business English, Medical English y más",
            "Disponibilidad para acceder a becas",
            "Acceso a la bolsa de trabajo EnglishJobs",
          ]}
          nonBenefits={[]}
          buttonText="Elige Pack Completo"
          buttonLink="https://wa.me/message/PUGX3ZO4SXBOG1"
        />
      </div>

      {/* Mobile Plan Cards */}
      <div className="planes-section-cards mobile-only">
        <PlanesCardMobile
          title="Pack Mensual"
          badge="Mensual"
          description="¡Perfecto para empezar!"
          price="S/99/mes"
          priceDetails="Cobro mensual recurrente"
          buttonLink="https://wa.me/message/PUGX3ZO4SXBOG1"
        />
        <PlanesCardMobile
          title="Pack Semestral"
          badge="Semestral"
          description="¡Aprende por Nivel!"
          price="S/299/x 4 meses"
          priceDetails="Cobro cada 4 meses de S/299"
          buttonLink="https://wa.me/message/PUGX3ZO4SXBOG1"
        />
        <PlanesCardMobile
          title="Pack Completo"
          badge="Anual"
          description="¡Todos los niveles!"
          price="S/699/x 12 meses"
          priceDetails="Cobro único de S/699"
          buttonLink="https://wa.me/message/PUGX3ZO4SXBOG1"
        />
      </div>
    </section>
  );
}

export default PlanesSection;
