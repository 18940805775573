import React from 'react';
import './Nosotros.css';

// Reuse existing components
import SecondaryNavbar from '../../components/secondaryNavbar/SecondaryNavbar';
import Footer from '../../components/footer/footer';

// Placeholder images (all point to the same ChicaEstudiandoIngles.svg for now)
import ChicaEstudiandoIngles from '../../assets/ChicaEstudiandoIngles.svg';
import ClasesConversacionTab from '../../assets/ClasesConversacionTab.svg';
import ProfesoresExperienciaTab from '../../assets/ProfesoresExperienciaTab.svg';
import ClasesVirtuales from '../../assets/ClasesVirtuales.svg';
import Profesores from '../../assets/Profesores.svg';
import Speaking from '../../assets/Speaking.svg';
import Certificado from '../../assets/Certificado.svg';
// Avatars for Historias de Éxito (just examples)
import AvatarCamila from '../../assets/Foto-Camila.png';
import AvatarAndres from '../../assets/Foto-Andres.png';
import AvatarMariana from '../../assets/Foto-Mariana.png';

function Nosotros() {
  return (
    <div className="nosotros-page">
      {/* 1) Hero Section */}
      <div className="about-hero">
        <div className="about-content flex flex-col md:flex-row">
          <img
            src={ChicaEstudiandoIngles}
            alt="Nuestro equipo en Englishmate"
            className="about-img"
          />
          <div className="about-text">
            <h1 className="title">¿Quienes somos?</h1>
            <p className="about-description">
              Somos un equipo de apasionados por la educación que 
              cree en hacer el aprendizaje del inglés{' '}
              <strong>accesible, dinámico y divertido</strong>.
            </p>
            <div className="about-buttons">
              <button
                className="button orange-button"
                onClick={() =>
                  window.open('https://wa.me/message/PUGX3ZO4SXBOG1', '_blank')
                }
              >
                Contáctanos
              </button>
              <button
                className="button blue-button"
                onClick={() =>
                  window.open('https://wa.me/message/PUGX3ZO4SXBOG1', '_blank')
                }
              >
                Empieza a Aprender
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* 2) Secondary Navbar */}
      <SecondaryNavbar />

      {/* 3) Nuestra Misión (white section) */}
      <div className="section-container mission-section">
        <div className="section flex flex-col md:flex-row">
          <div className="section-text-right">
            <h2>Nuestra Misión</h2>
            <p>
              Brindar un ambiente de aprendizaje integral para que cada alumno
              desarrolle su potencial y <strong>domine el inglés en solo 12 meses</strong>. 
              Impulsamos tu crecimiento con métodos interactivos y herramientas digitales.
            </p>
          </div>
          <div className="section-image">
            <img 
              src={ClasesConversacionTab} 
              alt="Nuestra misión en Englishmate" 
              className="section-img" 
            />
          </div>
        </div>
      </div>

      {/* 4) Nuestra Visión (light gray background for contrast) */}
      <div className="section-container vision-section">
        <div className="section flex flex-col md:flex-row">
          
          <div className="section-image">
            <img
              src={ProfesoresExperienciaTab}
              alt="Visión Englishmate"
              className="section-img"
            />
          </div>
          <div className="section-text-left">
            <h2>Nuestra Visión</h2>
            <p>
              Queremos ser la comunidad de inglés líder en Latinoamérica,
              reconocida por su <strong>excelencia académica</strong> y por 
              conectar a estudiantes de todas las edades en torno a una meta 
              común: <em>dominar el idioma inglés</em>.
            </p>
          </div>
        </div>
      </div>

      {/* 5) ¿Por qué Englishmate? (darker section background to highlight) */}
      <div className="section-container porque-section">
        <div className="section flex flex-col">
          <h3 className="section-title text-white">¿Por qué Englishmate?</h3>
          {/* Recreated Aprende-like layout with the same 4 items */}
          <div className="porque-features flex flex-wrap justify-center">
            <div className="porque-item">
              <img 
                src={ClasesVirtuales} 
                alt="Clases online en vivo" 
                className="porque-icon" 
              />
              <p>Clases online en vivo</p>
            </div>
            <div className="porque-item">
              <img 
                src={Profesores} 
                alt="Profesores con nivel nativo" 
                className="porque-icon" 
              />
              <p>Profesores con nivel nativo</p>
            </div>
            <div className="porque-item">
              <img 
                src={Speaking} 
                alt="Talleres gratis de speaking" 
                className="porque-icon" 
              />
              <p>Talleres gratis de speaking</p>
            </div>
            <div className="porque-item">
              <img 
                src={Certificado} 
                alt="Certificado en cada nivel" 
                className="porque-icon" 
              />
              <p>Certificado en cada nivel</p>
            </div>
          </div>
        </div>
      </div>

      {/* 6) Historias de Éxito (white background again) */}
      <div className="section-container testimonials-section">
        <div className="section flex flex-col">
          <h2 className="section-title">Historias de Éxito</h2>
          <p className="section-subtitle">
            Esto es lo que dicen algunos de nuestros estudiantes.
          </p>
          <div className="testimonials-grid mt-10 flex flex-wrap justify-center">
            <div className="testimonial-card">
              <div className="testimonial-top">
                <img
                  src={AvatarCamila}
                  alt="Foto de Andrea"
                  className="testimonial-avatar"
                />
                <div>
                  <p className="testimonial-author">Camila Reategui</p>
                  <p className="testimonial-location">Lima, Perú</p>
                </div>
              </div>
              <p className="testimonial-quote">
              "Gracias a Englishmate, he podido obtener mi certificado y aprobar el TOEFL, 
              lo que me permitió cumplir mi sueño de trabajar en una aerolínea internacional."
              </p>
            </div>
            <div className="testimonial-card">
              <div className="testimonial-top">
                <img
                  src={AvatarAndres}
                  alt="Foto de Luis"
                  className="testimonial-avatar"
                />
                <div>
                  <p className="testimonial-author">Andres Rojas</p>
                  <p className="testimonial-location">Bogotá, Colombia</p>
                </div>
              </div>
              <p className="testimonial-quote">
              "Englishmate me ayudó a estudiar inglés y, en solo un año, 
              logré mi sueño de viajar a Australia gracias a su increíble metodología."
              </p>
            </div>
            <div className="testimonial-card">
              <div className="testimonial-top">
                <img
                  src={AvatarMariana}
                  alt="Foto de Mariana"
                  className="testimonial-avatar"
                />
                <div>
                  <p className="testimonial-author">Mariana García</p>
                  <p className="testimonial-location">Ciudad de México, México</p>
                </div>
              </div>
              <p className="testimonial-quote">
              "Los profesores y asesores son increíbles. Lo que más me gusta es el Discord, 
              donde siempre organizan actividades para practicar inglés 24/7. ¡Los recomiendo 
              muchísimo!"
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* 7) Final CTA: ¿Listo para unirte a Englishmate? (light gray background) */}
      <div className="section-container cta-section">
        <div className="section flex flex-col items-center justify-center text-center">
          <h2 className="section-title">¿Listo para unirte a Englishmate?</h2>
          <p className="section-subtitle">
            Desbloquea tu potencial y domina el inglés con nuestros cursos 
            diseñados para tu éxito.
          </p>
          <button
            className="button orange-button section-button"
            style={{ margin: '0 auto' }}
            onClick={() =>
              window.open('https://wa.me/message/PUGX3ZO4SXBOG1', '_blank')
            }
          >
            Matricúlate Ahora
          </button>
        </div>
      </div>

      {/* 8) Footer */}
      <Footer />
    </div>
  );
}

export default Nosotros;
